<template>
  <TunnelLayout>
    <template slot="form">
      <div class="flex flex-col justify-around my-0 mx-auto sm:w-full sm:px-8">
        <h2 class="font-bold text-2xl mb-4 sm:text-base text-promy-blue-300">
          Commencez par saisir <br />
          l’adresse de votre bien
        </h2>
        <div class="grid grid-cols-4 gap-2 items-baseline">
          <pro-auto-complete
            is_auto_complete_cross_api
            placeholder="Recherche"
            class="col-span-3 lg:col-span-full"
            bordered
            :list="autocomplete_items"
            v-model="form.informations.adresse"
            :args="['properties', 'label']"
            @setItem="setItemForAutocomplete"
            :is_loading="is_autocomplete_fetching"
            :is_searchable="true"
            :rules="'required'"
            typeInput="tunnel"
          />
          <pro-button
            class="col-span-1 lg:col-span-full"
            @click="StepTwo"
            btn="primary"
          >
            C’est parti
          </pro-button>
        </div>
        <!--         <ul
          v-if="!user.can_create_reveler && !$route.params.id_revelation"
          class="ml-4 list-disc mt-6 text-xs text-promy-green-350 space-y-2"
        >
          <li>
            Vous pourrez faire {{ user.has_limit_adress_of }} nouvelles demandes
            de révélation dès le
            <strong>{{ user.can_create_reveler_until }}</strong>
          </li>
        </ul> -->
      </div>
    </template>
    <template slot="right-side">
      <div
        class="bg-no-repeat bg-cover bg-center lg:hidden"
        style="background-image: url(/images/Tunnel/Step1.svg)"
      ></div>
    </template>
  </TunnelLayout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      autocomplete_items: [],
      is_autocomplete_fetching: false,
      AddressIsSelecting: false,
      prevRoute: null,
    }
  },
  computed: {
    disabledButton() {
      if (this.$route.params.id_revelation) {
        return false
      }
      if (this.invalid || !this.user.can_create_reveler) {
        return true
      }
    },
    ...mapGetters({
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      address_cross_gu: 'address/address',
      informations: 'address/informations',
      user: 'auth/user',
    }),
    adresse: {
      get() {
        return this.$store.getters['Tunnel/GET_ADRESSE']
      },
      set(value) {
        this.$store.commit('Tunnel/SET_ADRESSE', value)
      },
    },
  },
  created() {
    if (
      this.$route.params.id_revelation &&
      this.cadastre.is_loaded_cross_revelation
    ) {
      this.getInfosCadastre(this.form.informations.coords_parcelle_principale)
    }
  },
  mounted() {
    if (!this.form.informations.adresse && this.address_cross_gu) {
      this.form.g_p_adresse_id = this.$route.query.id_address
        ? this.$route.query.id_address
        : null
      let address = { ...this.address_cross_gu }
      this.AddressIsSelecting = true
      this.form.informations.adresse = address.information.properties.label
      this.form.informations.coords_parcelle_principale =
        address.information.geometry.coordinates
      this.form.informations.current_parcelle = {
        ...this.current_parcelle,
      }
      this.form.informations.parcelles_voisines = [...this.parcelles_voisines]
      let infos_cadastre = { ...this.informations.cadastre }
      let temp_infos = infos_cadastre.informations[0]

      let informations = {
        geospatial: infos_cadastre.geospatial,
        geospatial_autour: infos_cadastre.geospatial_autour,
        code_insee: temp_infos.code_insee,
        code_postal: temp_infos.code_postal,
        departement: temp_infos.departement,
        region: temp_infos.region,
        zones_urbanisme: infos_cadastre.informations[0].zone_urba_obj,
      }
      this.cadastre.is_loaded_cross_gu = true
      this.setInformationCrossApiCadastre(informations)
    }
  },
  methods: {
    StepTwo() {
      this.$store.commit('Tunnel/SET_STEP_NUMBER', 2)
      this.$router.push({
        path: `/revelation/steps/2/${this.$route.params.id_revelation ?? ''}`,
        query: { id_address: this.$route.query.id_address },
      })
    },
    setItemForAutocomplete(adresse = null) {
      this.$store.commit('map/REMOVE_MAP')
      this.AddressIsSelecting = true
      this.form.informations.adresse = adresse ? adresse.properties.label : null
      this.form.informations.coords_parcelle_principale =
        adresse.geometry.coordinates
      this.form.informations.current_parcelle = null
      this.form.informations.parcelles_voisines = []
      this.cadastre.is_loaded_cross_gu = false
      this.cadastre.is_loaded_cross_revelation = false
      this.getInfosCadastre(adresse.geometry.coordinates)
    },
    searchAfterDebounce: _.debounce(
      function () {
        this.fetchAdresses()
      },
      800, // 800 milliseconds
    ),
    async fetchAdresses() {
      this.is_autocomplete_fetching = true
      let response = await this.$http.get('grand-publics/gouv-url-reveler', {
        params: {
          limit: 20,
          q: this.form.informations.adresse,
        },
      })
      this.autocomplete_items = []
      if (response.data.hasOwnProperty('features')) {
        this.autocomplete_items = response.data.features
      }
      this.is_autocomplete_fetching = false
    },

    getInfosCadastre(coords) {
      this.cadastre.error_parcelle_not_found = null
      this.cadastre.is_api_loading = true
      let api_cadastre = `/infosCadastre?lon=${coords[0]}&lat=${coords[1]}`

      this.$http
        .get(api_cadastre)
        .then((response) => {
          this.setInformationCrossApiCadastre(response.data)
        })
        .catch((err) => {
          this.cadastre.error_parcelle_not_found =
            'Informations de la parcelle non disponibles'
        })
    },
    setInformationCrossApiCadastre(information) {
      this.$store.commit('map/REMOVE_MAP')
      this.cadastre.data = information
      this.form.informations.code_insee = information.code_insee
      this.form.informations.code_postal = information.code_postal
      this.form.informations.departement = information.departement
      this.form.informations.region = information.region
      this.form.informations.zonage_plu = information.zones_urbanisme
      this.cadastre.is_api_loading = false
    },
  },
  watch: {
    'form.informations.adresse': {
      handler() {
        if (!this.AddressIsSelecting) {
          this.searchAfterDebounce()
        }

        this.AddressIsSelecting = false
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
}
</script>
